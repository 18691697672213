var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { id: "login", "fill-height": "", tag: "section" } },
    [
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _c(
            "v-slide-y-transition",
            { attrs: { appear: "" } },
            [
              _c(
                "base-material-card",
                {
                  staticClass: "px-5 py-3",
                  attrs: {
                    color: "success",
                    light: "",
                    "max-width": "100%",
                    width: "400"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "heading",
                      fn: function() {
                        return [
                          _c(
                            "div",
                            { staticClass: "text-center" },
                            [
                              _c(
                                "h1",
                                {
                                  staticClass: "display-2 font-weight-bold mb-2"
                                },
                                [_vm._v("\n              Login\n            ")]
                              ),
                              _vm._l(_vm.socials, function(social, i) {
                                return _c(
                                  "v-btn",
                                  {
                                    key: i,
                                    staticClass: "ma-1",
                                    attrs: {
                                      href: social.href,
                                      icon: "",
                                      rel: "noopener",
                                      target: "_blank"
                                    }
                                  },
                                  [
                                    _c("v-icon", {
                                      domProps: {
                                        textContent: _vm._s(social.icon)
                                      }
                                    })
                                  ],
                                  1
                                )
                              })
                            ],
                            2
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "text-center" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "text-center grey--text body-1 font-weight-light"
                        },
                        [_vm._v("\n            Or Be Classical\n          ")]
                      ),
                      _c("v-text-field", {
                        staticClass: "mt-10",
                        attrs: {
                          color: "secondary",
                          label: "First Name...",
                          "prepend-icon": "mdi-face"
                        }
                      }),
                      _c("v-text-field", {
                        attrs: {
                          color: "secondary",
                          label: "Email...",
                          "prepend-icon": "mdi-email"
                        }
                      }),
                      _c("v-text-field", {
                        staticClass: "mb-8",
                        attrs: {
                          color: "secondary",
                          label: "Password...",
                          "prepend-icon": "mdi-lock-outline"
                        }
                      }),
                      _c(
                        "pages-btn",
                        {
                          staticClass: "v-btn--text success--text",
                          attrs: { large: "", color: "", depressed: "" }
                        },
                        [_vm._v("\n            Let's Go\n          ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }